import request from './request'

const api = {
	// 列表
	lists: '/Order/index',
	// 新增
	add: '/Order/create',
	// 删除
	dele: '/Order/delete',
	//详情
	read: '/Order/read',
	// 编辑
	edit: '/Order/update',
	// 业务5级分类列表
	getFiveCateList: '/BusinessCate/getFiveCateList',
	// 诉求类型列表
	getList: '/AppealCate/getList',
	// 用户列表
	getUserList: '/User/getList',
	//退单审核
	ChargebackAudit: '/Order/ChargebackAudit',
	//重新派单
	distribute: '/Order/distribute',
	//工单修改时间
	updateTime: '/Order/updateTime',
	//导出
	daochu: '/Order/export',
	// 地区部门
	District: '/District/index',
	// 短信催办
	SmsUrge: '/Order/SmsUrge',
	// 编辑派单
	editDispatch: '/Order/editDispatch',
}

export {
	request,
	api
}
