<template>
	<div class="dlscreen">
		<el-drawer size="35%" @close="close" v-model="drawer" title="高级筛选">
			<ul>
				<li class="li screen">
					<el-input clearable type="text" v-model="form.order_number" placeholder="请输入订单编号">
					</el-input>
				</li>

				<li class="li">
					<el-select clearable v-model="form.type" placeholder="请选择工单类别">
						<el-option label="直办工单" :value="0"></el-option>
						<el-option label="联合工单" :value="1"></el-option>
					</el-select>
				</li>

				<li class="li">
					<el-select clearable v-model="form.subject" placeholder="请选择诉求主体">
						<el-option label="企业" :value="1"></el-option>
						<el-option label="个人" :value="0"></el-option>
					</el-select>
				</li>
				<li class="li">
					<el-select clearable v-model="form.position_type" placeholder="请选择职位类型">
						<el-option v-if="$store.state.userinfo.roles==1" label="督查室" :value="0"></el-option>
						<el-option v-if="$store.state.userinfo.roles==1" label="县纪委" :value="1"></el-option>
						<el-option label="区域网格总长" :value="2"></el-option>
						<el-option label="区域网格长" :value="3"></el-option>
						<el-option label="网格长" :value="4"></el-option>
						<el-option label="网格员" :value="5"></el-option>
					</el-select>
				</li>
				<li class="li screen">
					<el-input clearable type="text" v-model="form.position_name" placeholder="请输入职位人员">
					</el-input>
				</li>
				<li class="li" v-if="$store.state.userinfo.roles==1&&departmentid==''">
					<el-select clearable v-model="regionid" @change="regionchange" placeholder="请选择地区">
						<el-option v-for="item in region" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li class="li" v-if="$store.state.userinfo.roles==1&&regionid==''">
					<el-select clearable v-model="departmentid" @change="departmentchange" placeholder="请选择部门">
						<el-option v-for="item in department" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li class="li">
					<el-select clearable filterable v-model="form.business_cate_id" placeholder="业务分类">
						<el-option v-for="item in ywoptions" :key="item.id" :label="item.mergename" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li class="li">
					<el-date-picker clearable v-model="time" @change="changeTime" type="datetimerange" unlink-panels
						range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
					</el-date-picker>
				</li>
			</ul>
			<!-- 底部按钮 -->
			<div class="footer">
				<div>
					<el-button size="mini" @click="drawer=false">取消</el-button>
				</div>
				<div>
					<el-button color="#5473E8" class="el-white" style="color: white" size="mini" @click="SubmitForm">确定
					</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	export default {
		data() {
			return {
				drawer: false,
				ywoptions: [],
				departmentid: '',
				department: [],
				regionid: '',
				region: [],
				form: {
					page: 1,
					limit: 15,
					status: '',
					name: '',
					special: '',
					district_id: '',
					appeal_cate_id: '',
					order_sn: '',
					subject: '',
					business_cate_id: '',
					start_time: '',
					completion_time: '',
					order_number: '',
					position_type: '',
					position_name: '',
					type: ''
				},
				time: ''
			}
		},
		mounted() {
			this.getFiveCateList()
			this.getcounty()
			this.getdepartment()
		},
		methods: {
			// 选择部门
			departmentchange(e) {
				this.regionid = ""
				this.form.district_id = ""
				this.form.district_id = e
			},
			// 获取二级部门
			getdepartmenter(id) {
				this.$http.post(api.District, {
						parent_id: id,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.department = res.data.list
						}
					})
			},
			// 获取部门
			getdepartment() {
				this.$http.post(api.District, {
						parent_id: 0,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							console.log(res, "部门")
							this.getdepartmenter(res.data.list[0].id)
						}
					})
			},
			// 选择镇
			regionchange(e) {
				this.form.district_id = ""
				this.departmentid = ""
				this.form.district_id = e
			},
			// 获取镇
			gettown(id) {
				this.$http.post(api.District, {
						parent_id: id,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.region = res.data.list
						}
					})
			},
			// 获取县
			getcounty() {
				this.$http.post(api.District, {
						parent_id: 0,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.gettown(res.data.list[0].id)
						}
					})
			},
			// 获取业务
			getFiveCateList() {
				this.$http.post(api.getFiveCateList)
					.then((res) => {
						if (res.code == 1) {
							this.ywoptions = res.data
						}
					})
			},
			// 关闭
			close() {
				this.drawer = false
			},
			// 提交
			SubmitForm() {
				this.$EventBus.emit("FSUBMITFORM", this.form)
				this.drawer = false
			},
			// 选择时间
			changeTime(e) {
				if (e) {
					this.form.start_time = this.$Moment(e[0]).format('YYYY-MM-DD HH:mm:ss')
					this.form.completion_time = this.$Moment(e[1]).format('YYYY-MM-DD HH:mm:ss')
				} else {
					this.time = ''
					this.form.start_time = ''
					this.form.completion_time = ''
				}
			},
			show(form) {
				this.form = form
				if (!form.district_id) {
					this.departmentid = ''
					this.regionid = ''
				}
				this.drawer = true
				if (this.form.start_time == "") {
					this.time = ''
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.footer {
		position: absolute;
		bottom: 60px;
		left: 0;
		width: 100%;
		z-index: 9;
		display: flex;
		align-items: center;
		justify-content: center;

		div {
			margin: 0 20px;
		}
	}

	.li {
		margin-top: 20px;
		text-align: center;
	}
</style>
